import { template as template_fb2b80b29be1430ba95e2fae8dc2a6bf } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_fb2b80b29be1430ba95e2fae8dc2a6bf(`
  <div class="instructions-content" tabindex="0">
    <p class="instructions-content__paragraph">
      {{t "pages.certification-instructions.steps.4.text" htmlSafe=true}}
    </p>
    <ol class="instructions-content__list instructions-content__list--decimal">
      <li>{{t "pages.certification-instructions.steps.4.list.1"}}</li>
      <li>{{t "pages.certification-instructions.steps.4.list.2"}}</li>
      <li>{{t "pages.certification-instructions.steps.4.list.3"}}</li>
      <li>{{t "pages.certification-instructions.steps.4.list.4"}}</li>
    </ol>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
