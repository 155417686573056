import { template as template_ab7b931740c5422ba28b601bf44ca642 } from "@ember/template-compiler";
export default template_ab7b931740c5422ba28b601bf44ca642(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
