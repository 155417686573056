import { template as template_fd8ffa0aca8e4058803a8a5ac4fbc1ab } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_fd8ffa0aca8e4058803a8a5ac4fbc1ab(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
