import { template as template_f02eb7698e2e4762bad7cc4fed0a45a8 } from "@ember/template-compiler";
export default template_f02eb7698e2e4762bad7cc4fed0a45a8(`
  <div class="campaign-step">
    <img class="campaign-step__image" role="presentation" src={{@step.image.src}} width="60" height="60" />
    <h3 class="campaign-step__title">{{@step.title}}</h3>
    <p class="campaign-step__description">{{@step.description}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
