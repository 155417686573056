import { template as template_32e3b2209738420181e79ea3f2a5b503 } from "@ember/template-compiler";
import { element } from 'ember-element-helper';
import ModuleElement from './module-element';
export default class ModulixCustomElement extends ModuleElement {
    static{
        template_32e3b2209738420181e79ea3f2a5b503(`
    <div class="element-custom">
      {{#let (element @component.tagName) as |Tag|}}
        <Tag props={{@component.props}} />
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
