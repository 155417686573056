import { template as template_23509f10de704887b6f691e415223fc2 } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_23509f10de704887b6f691e415223fc2(`
  <ol class="module-objectives">
    {{#each @objectives as |objective|}}
      <li class="module-objectives__item">{{htmlUnsafe objective}}</li>
    {{/each}}
  </ol>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
