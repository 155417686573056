import { template as template_489a16fa2cce400b8829c505cbcb93a5 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import FooterLinks from '../footer/footer-links';
import LanguageSwitcher from '../language-switcher';
export default class Footer extends Component {
    @service
    currentDomain;
    @service
    intl;
    @service
    locale;
    @service
    router;
    get isInternationalDomain() {
        return !this.currentDomain.isFranceDomain;
    }
    get selectedLanguage() {
        return this.intl.primaryLocale;
    }
    @action
    onLanguageChange(language) {
        this.locale.setLocale(language);
        this.router.replaceWith({
            queryParams: {
                lang: null
            }
        });
    }
    static{
        template_489a16fa2cce400b8829c505cbcb93a5(`
    <footer class="authentication-layout-footer" role="contentinfo">
      {{#if this.isInternationalDomain}}
        <LanguageSwitcher @selectedLanguage={{this.selectedLanguage}} @onLanguageChange={{this.onLanguageChange}} />
      {{/if}}
      <FooterLinks @size="extra-small" />
    </footer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
